const legal_entity = {
    name: 'legal_entity',
    title: 'Юр. лица',
    url: '/refs/legal_entity',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.legal_entity.list',
        read: 'v1.legal_entity.read',
        create: 'v1.legal_entity.create',
        update: 'v1.legal_entity.update',
        delete: 'v1.legal_entity.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
    ],
};

export {
    legal_entity,
};
